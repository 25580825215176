<template>
  <tr v-if="mode === 'create' || mode === 'edit'">
    <td><input type="datetime-local" :value="booking.details.dueDate ? toIsoString(new Date(booking.details.dueDate)) : null" @change="(e) => handleDate('due', e.target.value)"></td>
    <td><input type="text" placeholder="Reg. No." v-model="booking.details.regNo" required></td>
    <td>
      <input type="text" placeholder="Make & Model" v-model="booking.details.make">
      <input type="text" placeholder="Miles" v-model="booking.details.miles">
    </td>
    <td>
      <InputSearch mode="queue" :initValue="booking.client.name" :selectedClient="booking.client" @selected-client="handleClient" @add-client="handleNewClient" />
      <input type="text" v-model="booking.client.tel" placeholder="Client Phone Number">
    </td>
    <td><input type="number" v-model="booking.details.ramp" min="1" max=3 placeholder="Ramp"></td>
    <td><input type="text" v-model="booking.details.description" placeholder="Optional"></td>
    <td><input type="checkbox" v-model="booking.settings.inQueue" title="In Queue?"></td>
    <td>
      <div v-if="mode === 'edit'" class="checkboxes">
        <input type="checkbox" id="priceAgreed" v-model="booking.settings.priceAgreed" title="Price Agreed?">
        <input type="checkbox" id="partOrdered" v-model="booking.settings.partOrdered" title="Parts Ordered?">
        <input type="checkbox" id="inGarage" v-model="booking.settings.inGarage" title="In Garage?">
      </div>
    </td>
    <td class="button-area">
      <button v-if="!isLoading && mode === 'edit'" @click="handleSave">Update</button>
      <button v-if="!isLoading && mode === 'edit'" @click="mode = null" class="cancel">Cancel</button>
      <button v-if="!isLoading && mode === 'create'" class="secondary" @click="handleSave">Add</button>
      <button v-else-if="isLoading && mode === 'edit'" disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
      <button v-else-if="isLoading && mode === 'create'" class="secondary" disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
    </td>
  </tr>
  <tr v-else @click="handleRoute">
    <td :class="{ tilted: !booking.details.dueDate }">{{ booking.details.dueDate ? new Date(booking.details.dueDate).toLocaleString().slice(0, 17) : 'Not Set' }}</td>
    <td>{{ booking.details.regNo }}</td>
    <td :class="{ tilted: !booking.details.make }">{{ booking.details.make || 'Unknown' }}</td>
    <td><router-link :to="{ name: 'Client', params: { id: booking.client.id } }">{{ booking.client.name }}</router-link></td>
    <td>{{ booking.details.ramp }}</td>
    <td class="description" :class="{ tilted: !booking.details.description }">{{ booking.details.description || 'N/A' }}</td>
    <td>
      <input type="checkbox" id="inQueue" v-model="booking.settings.inQueue" title="In Queue?" disabled>
    </td>
    <td>
      <div class="checkboxes">
        <input type="checkbox" id="priceAgreed" v-model="booking.settings.priceAgreed" title="Price Agreed?" disabled>
        <input type="checkbox" id="partOrdered" v-model="booking.settings.partOrdered" title="Parts Ordered?" disabled>
        <input type="checkbox" id="inGarage" v-model="booking.settings.inGarage" title="In Garage?" disabled>
      </div>
    </td>
    <td class="button-area">
      <button v-if="!isLoading && !mode" @click="mode = 'edit'">Edit</button>
    </td>
  </tr>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import useFetch from '@/composables/useFetch'
import { Toast } from '@/composables/useSwal'
import toIsoString from '@/composables/getIsoDate'
import InputSearch from '../Client/InputSearch.vue'

export default {
  props: ['mode', 'booking', 'selectedClient'],
  components: { InputSearch },
  setup(props) {
    const router = useRouter()
    const { error, isLoading, fetchData } = useFetch()
    const mode = ref(props.mode)
    const booking = ((!props.mode || props.mode === 'edit') && props.booking) 
      ? ref(props.booking)
      : ref({
          details: {
            regNo: null,
            make: null,
            miles: null,
            description: null,
            startDate: Date.now(),
            dueDate: null,
            ramp: 1
          },
          client: {
            id: props.selectedClient?.id || null,
            name: props.selectedClient?.name || null,
            tel: props.selectedClient?.tel || null
          },
          settings: {
            inQueue: true
          }
        })

    const handleRoute = (e) => {
      if (mode.value == 'edit' || !booking.value.id || e.target.tagName === 'A') {
        return
      }

      router.push({ name: 'Booking', params: { id: booking.value.id } })
    }

    const handleDate = (date, value) => {
      if (date === 'start') {
        booking.value.details.startDate = new Date(value).getTime()
      } else if (date === 'due') {
        booking.value.details.dueDate = new Date(value).getTime()
      }
    }

    const handleClient = (client) => {
      booking.value.client = client
    }

    const handleNewClient = (newName, clearTel) => {
      booking.value.client.id = null
      booking.value.client.name = newName
      
      if (clearTel) {
        booking.value.client.tel = null
      }
    }

    const handleSave = async () => {
      if (!booking.value.details.regNo) {
        Toast.fire({
          icon: 'error',
          title: 'Hey there...',
          text: 'Please add a Reg. No.'
        })

        return
      }

      if (mode.value !== 'edit') {
        await fetchData('/bookings', 'post', booking.value)
      } else {
        await fetchData(`/bookings/${booking.value.id}`, 'patch', booking.value)
      }

      if (!error.value) {
        Toast.fire({
          icon: 'success',
          title: 'Success!',
          text: mode.value !== 'edit' ? 'Booking created successfully' : 'Booking updated successfully'
        })

        if (mode.value === 'edit') {
          mode.value = null
        } else if (mode.value === 'create') {
          booking.value = {
            details: {
              regNo: null,
              make: null,
              miles: null,
              description: null,
              startDate: Date.now(),
              dueDate: null,
              ramp: 1
            },
            client: {
              id: null,
              name: null,
              tel: null
            },
            settings: {
              inQueue: true
            }
          }
        }
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Uh-oh...',
          text: `Error: ${error.value}`
        })
      }
    }

    return { isLoading, mode, booking, toIsoString, handleRoute, handleDate, handleClient,
      handleNewClient, handleSave }
  }
}
</script>