<template>
  <div class="modal show">
    <div class="inner-modal">
      <div v-if="mode !== 'edit'" class="title">Create New Booking 🚗</div>    
      <div v-else class="title">Edit Booking 🚗</div>    
      <form @submit.prevent="() => handleSave(false)">
        <label>Registration Number:</label>
        <input type="text" placeholder="Reg. No." v-model="booking.details.regNo" required>
        <label>Make & Model:</label>
        <input type="text" placeholder="Car Make & Model" v-model="booking.details.make">
        <label>Mileage:</label>
        <input type="number" placeholder="Car Mileage" v-model="booking.details.miles" min="0">
        <label>Description:</label>
        <input type="text" placeholder="Short booking description" v-model="booking.details.description">
        <div v-if="!clientProp">
          <label>Client:</label>
          <InputSearch :selectedClient="booking.client" @selected-client="handleClient" @add-client="handleNewClient" @open-client-modal="handleModalRequest" />
        </div>
        <label>Add to Queue?</label>
        <input type="checkbox" v-model="booking.settings.inQueue">
        <label>Ramp:</label>
        <select v-model="booking.details.ramp">
          <option :value="1">1</option>
          <option :value="2">2</option>
          <option :value="3">3</option>
        </select>
        <label>Start Date:</label>
        <input type="datetime-local" :value="booking.details.startDate ? toIsoString(new Date(booking.details.startDate)) : null" @change="(e) => handleDate('start', e.target.value)">
        <label>Due Date:</label>
        <input type="datetime-local" :value="booking.details.dueDate ? toIsoString(new Date(booking.details.dueDate)) : null" @change="(e) => handleDate('due', e.target.value)">
        <div class="buttons">
          <button v-if="!isLoading" type="submit" class="swal2-styled swal2-confirm">Save</button>
          <button v-if="mode !== 'edit' && !isLoading" type="button" class="swal2-styled swal2-confirm" @click="() => handleSave(true)">Save & Add New</button>
          <button v-if="!isLoading" type="button" class="swal2-styled swal2-cancel" @click="handleClose">Close</button>
          <button v-else class="swal2-styled swal2-confirm" disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import useFetch from '@/composables/useFetch'
import { Toast } from '@/composables/useSwal'
import toIsoString from '@/composables/getIsoDate'
import InputSearch from '../Client/InputSearch.vue'

export default {
  props: ['mode', 'booking', 'client', 'clientProp'],
  components: { InputSearch },
  emits: ['add-client', 'modal-close'],
  setup(props, { emit }) {
    const { error, isLoading, fetchData } = useFetch()
    const booking = (props.mode === 'edit' && props.booking) 
      ? ref(props.booking) 
      : ref({
        details: {
          regNo: null,
          make: null,
          miles: null,
          description: null,
          startDate: Date.now(),
          dueDate: null,
          ramp: 1  
        },
        client: props.client || {
          id: null,
          name: null,
          tel: null
        },
        settings: {
          inQueue: false
        }
      })

    const handleDate = (date, value) => {
      if (date === 'start') {
        booking.value.details.startDate = new Date(value).getTime()
      } else if (date === 'due') {
        booking.value.details.dueDate = new Date(value).getTime()
      }
    }

    const handleClient = (client) => {
      booking.value.client = client
    }

    const handleNewClient = (newName, clearTel) => {
      booking.value.client.id = null
      booking.value.client.name = newName
      
      if (clearTel) {
        booking.value.client.tel = null
      }
    }

    const handleModalRequest = () => {
      emit('add-client')
    }

    const handleSave = async (keepOpen) => {      
      if (!booking.value.client || !booking.value.client.name) {
        Toast.fire({
          icon: 'error',
          title: 'Hey there...',
          text: 'Please pick a client, or enter a name for new client to be created'
        })

        return
      } else {
        if (props.mode !== 'edit') {
          await fetchData('/bookings', 'post', booking.value)

          if (!error.value) {
            if (!keepOpen) {
              emit('modal-close')
            } else {
              booking.value = {
                details: {
                  regNo: null,
                  make: null,
                  miles: null,
                  description: null,
                  startDate: Date.now(),
                  dueDate: null,
                  ramp: 1  
                },
                client: props.client || null,
                settings: {
                  inQueue: false
                }
              }
            }

            Toast.fire({
              icon: 'success',
              title: 'Success!',
              text: 'Booking created successfully'
            })
          } else {     
            Toast.fire({
              icon: 'error',
              title: 'Uh-oh...',
              text: `Error: ${error.value}`
            })
          }
        } else {
          await fetchData(`/bookings/${booking.value.id}`, 'patch', booking.value)

          if (!error.value) {
            emit('modal-close')

            Toast.fire({
              icon: 'success',
              title: 'Success!',
              text: 'Booking updated successfully'
            })
          } else {     
            Toast.fire({
              icon: 'error',
              title: 'Uh-oh...',
              text: `Error: ${error.value}`
            })
          }
        }
      }
    }

    const handleClose = () => {
      emit('modal-close')
    }

    return { booking, isLoading, handleDate, handleClient, handleSave, handleNewClient,
      handleModalRequest, handleClose, toIsoString }
  }
}
</script>