<template>
  <input type="text" v-model="searchTerm" @keyup.enter="() => getDocuments(false)" class="search-bar" placeholder="Search for Bookings (using Reg. No.)">
  <div class="select-wrap">
    <label>Filter:</label>
    <select v-model="bookingsFilter">
      <option value="all">All Bookings</option>
      <option value="active">Active Bookings</option>
      <option value="completed">Completed Bookings</option>
      <option value="queue">Bookings in Queue</option>
    </select>
  </div>
  <div class="select-wrap">
    <label>Ramp:</label>
    <select v-model="bookingsRamp">
      <option value="all">All</option>
      <option :value="1">1</option>
      <option :value="2">2</option>
      <option :value="3">3</option>
    </select>
  </div>
  <div class="select-wrap">
    <label>Direction:</label>
    <select v-model="orderDirection">
      <option value="asc">Ascending</option>
      <option value="desc">Descending</option>
    </select>  
  </div>
  <table>
    <thead>
      <th>Due Date</th>
      <th>Reg. No.</th>
      <th>Vehicle</th>
      <th>Client</th>
      <th>Ramp</th>
      <th class="description">Description</th>
      <th>In Queue</th>
      <th>Progress</th>
    </thead>
    <tbody v-if="bookings"> 
      <TableRow mode="create" v-if="!clientId" />
      <TableRow v-for="booking in bookings" :key="booking.id" :booking="booking"/>
    </tbody>
  </table>
  <div v-if="isLoading" class="loading">Fetching bookings... ⏳</div>
  <div v-if="!isLoading && bookings && bookings.length && isEmpty" class="success">That's all for now 👍</div>
  <div v-if="!isLoading && !error && (!bookings || !bookings.length)" class="error">No Bookings match selected criteria 🤷</div>
  <div v-if="!isLoading && error" class="error">{{ error }}</div>
  <div v-if="bookings && bookings.length && !isEmpty" class="load-more">
    <button @click="() => getDocuments(true)">Load More 👇</button>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import useFetch from '@/composables/useFetch'
import useFirestore from '@/composables/useFirestore'
import { Toast } from '@/composables/useSwal'
import TableRow from './TableRow.vue'

export default {
  props: ['clientId'],
  components: { TableRow },
  setup({ clientId }) {
    const { documents: bookings, isEmpty, error, isLoading, listenDocuments } = useFirestore()
    const { error: fetchError, isLoading: fetchLoad, fetchData } = useFetch()
    const router = useRouter()
    const bookingsRamp = ref('all')
    const orderDirection = ref('asc')
    const bookingsFilter = ref(clientId ? 'all' : 'active')
    const searchTerm = ref('')

    const getDocuments = (attach) => {
      const bookingsToFetch = bookingsFilter.value === 'all' ? null 
        : bookingsFilter.value === 'queue' ? ['settings.inQueue', '==', true]
        : bookingsFilter.value === 'completed' ? ['settings.isCompleted', '==', true]
        : ['settings.isCompleted', '==', false]
      
      listenDocuments(
        'bookings', 
        null, 
        null,
        attach,
        searchTerm.value ? null : ['details.dueDate', orderDirection.value], 
        !clientId ? null : ['client.id', '==', clientId],
        bookingsToFetch,
        bookingsRamp.value === 'all' ? null : ['details.ramp', '==', bookingsRamp.value],
        !searchTerm.value ? null : ['details.regNo', '>=', searchTerm.value.toUpperCase()],
        !searchTerm.value ? null : ['details.regNo', '<=', searchTerm.value.toUpperCase()+ '\uf8ff']
      )
    }
    
    getDocuments()

    watch(bookingsRamp, () => getDocuments())

    watch(orderDirection, () => getDocuments())

    watch(bookingsFilter, () => getDocuments())

    const handleChange = async (e, bookingId, settings) => {
      await fetchData(`/bookings/${bookingId}`, 'patch', {
        settings: {
          ...settings,
          [ e.target.id ]: e.target.checked
        }
      })
      
      if (!fetchError.value) {
        Toast.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Booking updated successfully'
        })
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Oops...',
          text: `Error: ${fetchError.value}`
        })
      }
    }

    return { bookings, isEmpty, error, isLoading, fetchLoad, bookingsRamp, orderDirection,
      bookingsFilter, searchTerm, getDocuments, handleChange }
  }
}
</script>